
.btn_noFocus:focus {
    outline: none !important;
    box-shadow: none !important;
}
.outline_none{
    outline: none !important;
    box-shadow: none !important;
}
/* common background & color */
.bg_light_ash{
    background: #EBECEC !important;
}
.bg_theme_color{
    background-color: #7BDDFF !important;
    color: #fff !important;
}
.border_theme_color{
    border-color: #7BDDFF !important;
}
.text_theme_color{
    color: #7BDDFF !important;
}

.text_booked{
    color: #7B7C7C !important;
}
.text_selected{
    color: #2B3DD4 !important;
}
.text_available{
    color: #19881A !important;
}
.text_sold{
    color: #B11A51 !important;
}
.text_light_ash{
    color: rgb(156, 156, 156) !important;
}
.bg_selected{
    background: #2B3DD4 !important;
    color: #fff !important;
}
.bg_available{
    background: #19881A !important;
    color: #fff !important;
}
.bg_booked{
    background: #7B7C7C !important;
    color: #fff !important;
}
.bg_sold{
    background: #B11A51 !important;
    color: #fff !important;
}
.bg_blocked{
    background: #181818 !important;
    color: #fff !important;
}
.opacity_1{
    opacity: 1!important;
}
.color_blue{
    color: #3E93D0;
}
.car_bg{
    background: #f0f0f0;
}
.bg_light_blue{
    background-color:#c0e5ff ;
}

/* common widht & height */
.w_2px{
    width: 2px;
}
.h_130px{
    height: 130px !important;
}

.h_120px{
    height: 120px !important;
}
.w_10{
    width: 10% !important;
}
.w_15{
    width: 15% !important;
}
.w_20{
    width: 20% !important;
}
.w_35{
    width: 35% !important;
}
.w_40{
    width: 40% !important;
}
.w_45{
    width: 45% !important;
}
.w_fit{
    width: fit-content;
}
.seat_size{
    width: 40px !important;
    height: 40px !important;
}
.h_150{
    height: 150px !important;
}
.w_150{
    width: 150px !important;
}
.w_50{
    width: 50px;
}
.w_fit_content{
    width: fit-content !important;
}
.h_50{
    height: 50px;
}
.min_h_40{
    min-height: 40px;
}

/* custom font size */
.font_12{
    font-size: 12px !important;
}
.font_14{
    font-size: 14px !important;
}
.font_16{
    font-size: 16px !important;
}
.font_18{
    font-size: 18px !important;
}
.font_20{
    font-size: 20px !important;
}
.font_22{
    font-size: 22px !important;
}
.font_24{
    font-size: 24px !important;
}

.selected_price_border{
    border: 2px solid #000 !important;
}
.non_selected_price_opacity{
    opacity: .6 !important;
}

/* custiom fullscreen modal size */
.full_screen_modal {
    width: 100%;
    max-width: none;
    height: 100vh;
    margin: 0 auto;
}

.full_screen_modal .modal-content{
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-backdrop{
    width: 100% !important;
    height: 100% !important;
}

.transition_anim{
     transition: transform .5s linear;
}


/* Common Border */
.border_radius_20{
    border-radius: 20px !important;
}
.border_weight_3{
    border-width: 3px !important;
}

.row{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 0 !important;
    padding-left: 0 !important;
}